(function () {
    'use strict';

    angular
        .module('app.schedulePacks')
        .component('classroomSchedule', {
            templateUrl: 'app/schedulePacks/components/classroomSchedule/classroomSchedule.html',
            controller: classroomSchedule,
            controllerAs: 'vm',
            bindings: {
                schedule: '=',
                availableInstructors: '=',
                availableLocations: '=',
                education: '=',
                isOpen: '='
            }
        });

    classroomSchedule.$inject = ['logger', 'programSchedulePackHelper', 'guidGeneratorDataService', '$scope', 'i18nFilter', 'contractsDataService', 'exceptionHelper', 'pricesCategory', 'dateHelper', 'bruto2Percentage', 'EducationActivityScheduleInstructorRoles', '$uibModal'];

    function classroomSchedule(logger, programSchedulePackHelper, guidGeneratorDataService, $scope, i18nFilter, contractsDataService, exceptionHelper, pricesCategory, dateHelper, bruto2Percentage, EducationActivityScheduleInstructorRoles, $uibModal) {
        var vm = this;        

        vm.api = {
            id: guidGeneratorDataService.getGuid(),
            checkIfFormIsValid: checkIfFormIsValid,
            getBudgetPieData: getBudgetPieData
        };

        vm.localeMessages = {
            noActiveContract: i18nFilter('translations.schedule_gui_no_active_contract'),
            noActiveContractCostFree: i18nFilter('translations.schedule_packs_info_no_active_contract_cost_free')
        };

        vm.add = function () {

            if (vm.schedule.instructors == null)
                vm.schedule.instructors = [];

            if (vm.schedule.isInstructorCostFree == false){
                
                var filterInstructors = vm.formData.availableInstructors.filter(function (instructor) {
                    let instructorContract = vm.dbContracts.filter(function (instContract) {
                        if (instContract.instructorId === instructor.id){
                            return true;
                        }
                        else{
                            return false;
                        }
                    });

                    if (instructorContract && instructorContract.length){
                        if (instructorContract[0].start == null || instructorContract[0].end == null || instructorContract[0].signingDate == null) {
                            return false;                        
                        }
                        else{
                            return true;
                        }
                    }
                });
            }
            else{
                filterInstructors = vm.formData.availableInstructors;
            }

            if (filterInstructors && filterInstructors.length){
                vm.schedule.instructors.push({
                    instructor: filterInstructors[0],
                    duration: 0,
                    costPerHour: 0,
                    extraCost: 0,
                    travelCost: 0
                });
            }
            else{
                vm.schedule.instructors.push({
                    instructor: null,
                    duration: 0,
                    costPerHour: 0,
                    extraCost: 0,
                    travelCost: 0
                });
            }

            vm.instructorsChanged(vm.schedule.instructors.length - 1);
        };

        vm.setPrice = function (instructor, role) {
            vm.schedule.prices.forEach(function (price) {
                if (price.instructorType == role.id) {
                    instructor.costPerHour = price.amount;
                }
            });

            recalculatePrices();
        };

        function openContractModal(instructordId) {           
            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: 'app/schedulePacks/instructorContractModal/instructorContractModal.html',
                controller: 'EditContractDialog',
                controllerAs: 'modalvm',
                resolve: {
                    instructor: function () { return instructordId; },
                    parentScope: function () { return vm; },
                    budgetEntities: ['budgetsDataService', function (budgetsDataService) {
                        return budgetsDataService.getBudgetEntities();
                    }]
                },
                windowClass: 'contractModal',
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            });

            modalInstance.result.then(function (result) {
                updateContracts();
            }, function () {
                // when users clicks cancel button
            });
        }

        vm.openContractModal = openContractModal;

        vm.remove = function (index) {
            vm.schedule.instructors.splice(index, 1);        
        };

        vm.filterRoles = function (instructorId, index) {
            var availableRolesForInstructorInTableRow = vm.formData.EducationActivityScheduleInstructorRoles.slice();

            for (var j = 0; j < vm.schedule.instructors.length; j++) {
                var instructorData = vm.schedule.instructors[j];
                if (j != index && instructorId === instructorData.instructor.id) {
                    var duplicateIndex = availableRolesForInstructorInTableRow.indexOf(instructorData.role);
                    if (duplicateIndex != -1)
                        availableRolesForInstructorInTableRow.splice(duplicateIndex, 1);
                }
            }
            return availableRolesForInstructorInTableRow;
        };

        vm.trigerChange = function (param) {
            vm.formData.selectedInstructors[param] = vm.schedule.instructors[param].instructor;
            vm.instructorsChanged(param);
        };

        vm.formData = {
            selectedInstructors: [],
            expanded: vm.isOpen,
            availableInstructors: vm.availableInstructors,
            availableLocations: vm.availableLocations,
            education: vm.education,
            instructorPrice: undefined,
            selectedInstructorRole: EducationActivityScheduleInstructorRoles[11],
            contracts: [],
            sums: {
                bruto1: 0.0,
                bruto2: 0.0
            },
            EducationActivityScheduleInstructorRoles: translateCollections(EducationActivityScheduleInstructorRoles),
            validationTriggered: false,
            instructorDataReady: false
        };

        if (vm.formData.availableInstructors.length > 0){
            let instructorsIds = vm.formData.availableInstructors.map(function (instructor) { return instructor.id; });
    
            getContracts(instructorsIds);
        }

        var availableRoles = [];
        vm.formData.EducationActivityScheduleInstructorRoles.forEach(function (role) {
            vm.schedule.prices.forEach(function (price) {
                if (price.instructorType == role.id) {
                    availableRoles.push(role);
                }
            });
        });

        if (availableRoles.length == 0) 
            availableRoles.push(vm.formData.EducationActivityScheduleInstructorRoles[0]);
        
        vm.formData.EducationActivityScheduleInstructorRoles = availableRoles;

        //#region Extrude instructor price form education
        if (vm.schedule.instructors != null || vm.schedule.instructors != undefined) {
            vm.schedule.instructors.forEach(function (instructor) {
                for (var index in vm.formData.EducationActivityScheduleInstructorRoles) {
                    if (instructor.role == index.id) {
                        instructor.role = vm.formData.EducationActivityScheduleInstructorRoles[index];
                        break;
                    }
                }
                instructor.travelCost = instructor.travelCost != null ? instructor.travelCost.toString().replace(".", ",") : 0;
                instructor.extraCost = instructor.extraCost != null ? instructor.extraCost.toString().replace(".", ",") : 0;
            });
        }
        vm.formData.instructorPrice = _.find(vm.formData.education.prices, function (price) { return price.category.id === pricesCategory.instructorPrice; });
        //#endregion

        //#region Dates        
        //#region Start
        vm.formData.startTimeConfig = {
            type: 'datetime',
            width: '100%',
            showClearButton: true,
            acceptCustomValue: false,            
            onValueChanged: function (e) {
                vm.schedule.startTime = dateHelper.createNewDateTimeWithoutSeconds(vm.schedule.startTime);
                fetchInitialContractsForInstructors();

                if (vm.formData.availableInstructors.length > 0){
                    let instructorsIds = vm.formData.availableInstructors.map(function (instructor) { return instructor.id; });
                    getContracts(instructorsIds);
                }
                if (vm.schedule.endTime && vm.schedule.startTime.getTime() >= vm.schedule.endTime.getTime()) {
                    vm.schedule.endTime = undefined;
                }
                openEndDatePicker();
            },
            inputAttr: {
                'name': 'startTime',
                'ng-required': 'true'
            },
            onInitialized: function (instance) {
                vm.formData.startDatePicker = instance.component;
            },
            bindingOptions: {
                value: 'vm.schedule.startTime',
                disabled: 'vm.schedule.scheduleLocked'
            }
        };      
        //#endregion

        //#region End
        vm.formData.endTimeConfig = {
            type: 'datetime',
            width: '100%',
            showClearButton: true,            
            acceptCustomValue: false,
            onValueChanged: function (e) {                
                vm.schedule.endTime = dateHelper.createNewDateTimeWithoutSeconds(vm.schedule.endTime);                
            },
            inputAttr: {
                'name': 'endTime',
                'ng-required': 'true'
            },
            onInitialized: function (instance) {
                vm.formData.endDatePicker = instance.component;
            },
            bindingOptions: {
                value: 'vm.schedule.endTime',
                min: 'vm.schedule.startTime',
                disabled: 'vm.schedule.scheduleLocked'
            }
        };       
        //#endregion
        //#endregion

        //#region Method binding
        vm.instructorsChanged = instructorsChanged;
        vm.toggle = toggle;
        vm.locationChange = locationChange;
        vm.recalculatePrices = recalculatePrices;
        vm.openStartDatePicker = openStartDatePicker;
        vm.openEndDatePicker = openEndDatePicker;
        //#endregion

        //#region Toggle
        function toggle() {
            vm.formData.expanded = !vm.formData.expanded;
        }

        function openStartDatePicker() {
            vm.formData.startDatePicker.open();
        }

        function openEndDatePicker() {
            vm.formData.endDatePicker.open();
        }
        //#endregion

        //#region API
        function checkIfFormIsValid() {
            if (vm.schedule.scheduleLocked) {
                return true;
            }

            vm.formData.validationTriggered = true;
            $scope.classroomScheduleFrom.freeSchedule.$touched = true;
            $scope.classroomScheduleFrom.locations.$touched = true;            
            //$scope.classroomScheduleFrom.instructors.$touched = true;   

            if (vm.schedule.location) {
                $scope.classroomScheduleFrom.capacityLearners.$touched = true;
            }

            var instructorCriteriaOk = true;
            var dateCriteriaOk = true;

            //All instructors must have valid contract if cost per hour is not 0 or instructors aren't volunteering
            if (!vm.schedule.isInstructorCostFree) {
                _.each(vm.schedule.instructors, function (instructor) {
                    if (!instructor.hasActiveContract) {
                        instructorCriteriaOk = false;
                    }                    
                });
            }
            
            //Start-end date validation
            if (!vm.schedule.startTime || !vm.schedule.endTime) {
                dateCriteriaOk = false;
            }
            else if (vm.schedule.startTime || vm.schedule.endTime) {
                dateCriteriaOk = vm.schedule.endTime.getTime() > vm.schedule.startTime.getTime();
            }

            //Todo add location check and may capacity check
           
            return instructorCriteriaOk && dateCriteriaOk && $scope.classroomScheduleFrom.$valid;
        }

        function translateCollections(data) {
            data.forEach(function (row) {
                row.name = i18nFilter(row.name);
            })
            return data;
        }
               
        function getBudgetPieData() {
            return {
                startTime: vm.schedule.startTime,
                total: vm.formData.sums
            };            
        }

        programSchedulePackHelper.registerDirective('schedule-' + vm.schedule.educationId, vm.api)

        $scope.$on('destroy', function () {
            programSchedulePackHelper.deregisterDirective('schedule-' + vm.schedule.educationId, vm.api.id);
        });
        //#endregion

        //#region Map instructors to selected instructors list
        _.each(vm.schedule.instructors, function (instructor) {
            vm.formData.selectedInstructors.push(angular.copy(instructor.instructor));
        })
        //#endregion

        //#region Fetch contracts for initial instructors
        function fetchInitialContractsForInstructors() {
            notifyBudgetPie();
            if (vm.schedule.instructors) {
                var instructorsIds = vm.schedule.instructors.map(function (instructor) { return instructor.instructor.id; });
                updateInstructorContract(instructorsIds);
            }

            else {
                vm.formData.instructorDataReady = true;
            }
        }

        fetchInitialContractsForInstructors();
        //#endregion
        
        //#region Change events
        function instructorsChanged(index) {
            var newInstructorsIds = [];

            if (!vm.schedule.instructors) 
                vm.schedule.instructors = [];
                      
            vm.schedule.instructors[index] = {
                instructor: vm.schedule.instructors[index].instructor,
                duration: 0,
                costPerHour: getInstructorCostPerHour(index),
                travelCost: 0,
                extraCost: 0,
                role: vm.formData.EducationActivityScheduleInstructorRoles[0]
            };
            if (!vm.schedule.instructors[index].instructor) {
                return
            }
            newInstructorsIds.push(vm.schedule.instructors[index].instructor.id);     

            if (newInstructorsIds.length !== 0) {
                updateInstructorContract(newInstructorsIds);               
            }
            else {
                //Instructors has changed recalculate prices
                recalculatePrices();
            }
        }

        function locationChange() {
            vm.schedule.capacityLearners = vm.schedule.location.capacity;
        }

        function recalculatePrices(checkDurationField) {
            //Reset values to zero
            vm.formData.sums.bruto1 = 0.0;
            vm.formData.sums.bruto2 = 0.0;

            if (vm.schedule.instructors == null) 
                vm.schedule.instructors = [];
            
            _.each(vm.schedule.instructors, function (instructor) {
                var index = vm.schedule.instructors.indexOf(instructor);
                instructor.costPerHour = getInstructorCostPerHour(index);
                var instructorContract = getInstructorContract(instructor.instructor.id);
                instructor.hasActiveContract = instructorContract !== undefined && instructorContract !== null;
              
                if (instructorContract) {
                    var filteredChecked = instructorContract.entities.filter(x => x.id == vm.formData.education.id);
                    instructor.hasActiveContract = filteredChecked.length > 0;
                    instructor.contractId = instructorContract.id;
                    instructor.contractNumber = instructorContract.contractNumber;
                }

                else {
                    delete instructor.contractId;
                    delete instructor.contractNumber;
                }

                if (!instructor.hasActiveContract && (!vm.schedule.isInstructorCostFree || instructor.costPerHour !== 0)) {
                    instructor.duration = 0;
                }

                instructor.bruto1 = calculateBruto1(instructor.duration, index);
                instructor.bruto2 = calculateBruto2(instructor.instructor.id, instructor.bruto1, instructor.travelCost, instructor.extraCost);
                
                var assignedRole = instructor.role == undefined ? EducationActivityScheduleInstructorRoles[0] :
                    EducationActivityScheduleInstructorRoles.filter(function (iRole) {
                        return iRole.id === instructor.role
                    });

                if (EducationActivityScheduleInstructorRoles.indexOf(instructor.role) != -1) 
                    instructor.role = instructor.role;                
                else if (assignedRole.length == 0) 
                    instructor.role = EducationActivityScheduleInstructorRoles[0];                
                else
                    instructor.role = assignedRole[0];
                    
                vm.formData.sums.bruto1 += instructor.bruto1;
                vm.formData.sums.bruto2 += instructor.bruto2;
            });

            notifyBudgetPie();
            vm.formData.instructorDataReady = true;
        }
        //#endregion

        //#region Utils
        function calculateBruto2(instructorId, bruto1, travelCost, extraCost) {
            if (vm.schedule.isInstructorCostFree) 
                return 0;

            var percentage = getPercentageForInstructor(instructorId);
            var travelCost = travelCost != null && travelCost != "" ? parseFloat(travelCost.toString().replace(',', '.')) : 0;
            var extraCost = extraCost != null && extraCost != "" ? parseFloat(extraCost.toString().replace(',', '.')) : 0;

            return bruto1 * percentage + travelCost + extraCost;
        }

        function getPercentageForInstructor(instructorId) {
            //Default value is used if instructor doesn't have related organization in contract (1.0938)
            var percentage = bruto2Percentage.unemployed;

            var instructorContract = getInstructorContract(instructorId);

            if (instructorContract && instructorContract.organization && instructorContract.organization.vatPayer) {
                //1.22 is used if instructor organization is vatPayer (1.22)
                percentage = bruto2Percentage.firmVatPayer;
            }
            else if (instructorContract && instructorContract.organization && !instructorContract.organization.vatPayer) {
                //1.0 is used if instructor organization is not vatPayer (1.0)
                percentage = bruto2Percentage.firm;
            }

            return percentage;
        }



        function updateContracts() {
            var instructorsIds = vm.schedule.instructors.map(function (instructor) { return instructor.instructor.id; });

            if (instructorsIds && instructorsIds.length && vm.schedule.startTime) {

                contractsDataService.getInstructorsContracts(instructorsIds, vm.formData.education.id, vm.schedule.startTime).then(function (data) {
                    vm.formData.contracts = data;
                    recalculatePrices();
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }
        vm.updateContracts = updateContracts;
        /**
         * Gets from Internet contracts for each instructor given in list. When request is complete result will be added to local contract list
         * @param {string[]} instructorsIds
         */
        function updateInstructorContract(instructorsIds) {

            if (instructorsIds && instructorsIds.length && vm.schedule.startTime) {
                contractsDataService.getInstructorsContracts(instructorsIds, vm.formData.education.id, vm.schedule.startTime).then(function (data) {
                    if (data == null || data == undefined) {
                        recalculatePrices();
                        return;
                    }

                    var arr = [];

                    vm.formData.contracts.forEach(function (contract) {
                        var dups = data.filter(function (new_contract) {
                            return contract.id == new_contract.id;
                        });

                        if (dups.length == 0) 
                            arr.push(contract);                       
                    });

                    vm.formData.contracts = arr.concat(data);   
                    
                    recalculatePrices();
                    vm.formData.instructorDataReady = true;
                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }

        /**
         * Gets contract for given instructor id. Contract is fetch from local list of contracts
         * @param {string} instructorId
         */
        function getInstructorContract(instructorId) {

            var instructorContracts = vm.formData.contracts.filter(function (contract) {
         
                var contractEndDate = new Date(contract.end);
                contractEndDate.setDate(contractEndDate.getDate() + 1);

                if (contract.start <= vm.schedule.startTime && vm.schedule.endTime.getDate() <= contractEndDate) {
                    var instructorIsInContract = _.some(contract.contractors, function (contractor) { return instructorId == contractor.id });

                    return instructorIsInContract;
                }
            })

            if (instructorContracts.length > 0) {
                var cc = instructorContracts.filter(function (x) {
                    for (var i = 0; i < x.entities.length; i++) {
                        if (x.entities[i].id == vm.formData.education.id) {
                            return true;
                        }
                    }
                    return false;                
                });
                
                if (cc.length == 0) {
                    return instructorContracts[0];
                }

                else if (cc.length > 0) {
                    return cc[0];
                }         
            }


            return undefined;
        }

        function calculateBruto1(duration, index) {
            return getInstructorCostPerHour(index) * parseInt(duration);
        }
        
        function getInstructorCostPerHour(index) {
            if (vm.schedule.isInstructorCostFree) 
                return 0;
            var bb = vm.schedule.instructors[index];
            var cc = vm.schedule.prices.filter(function (price) {
                var rez = false;
                if (bb.role != undefined && price.instructorType == bb.role.id) 
                    rez = true;
                
                else if (price.instructorType == bb.role) 
                    rez = true;
                
                else 
                    rez = false;
                
                return rez;
            })

            return cc.length == 0 ? 0 : cc[0].amount;
        }

        function notifyBudgetPie() {
            _.each(programSchedulePackHelper.getApisForKey('pie-' + vm.schedule.educationId), function (api) {
                api.scheduleChanged();                
            });            
        }

        function getContracts(instructorsIds) {

            if (instructorsIds && instructorsIds.length && vm.schedule.startTime) {
                contractsDataService.getInstructorsContracts(instructorsIds, vm.formData.education.id, vm.schedule.startTime).then(function (data) {
                    if (data == null || data == undefined) {
                        return;
                    }
                    else{
                        vm.dbContracts = data;
                    }

                }, function (error) {
                    exceptionHelper.handleException(error);
                });
            }
        }
        vm.dbContracts = [];
        vm.getcontracts = getContracts;
        
        function filterAvailableInstructors(){
            if (vm.schedule.isInstructorCostFree == false){
                
                var filterInstructors = vm.formData.availableInstructors.filter(function (instructor) {
                    let instructorContract = vm.dbContracts.filter(function (instContract) {
                        if (instContract.instructorId === instructor.id){
                            return true;
                        }
                        else{
                            return false;
                        }
                    });

                    if (instructorContract.length > 0){
                        if (instructorContract[0].start == null || instructorContract[0].end == null || instructorContract[0].signingDate == null) {
                            return false;                        
                        }
                        else{
                            return true;
                        }
                    }
                });
            }
            else{
                filterInstructors = vm.formData.availableInstructors;
            }
            
            return filterInstructors;
        }
        vm.filterAvailableInstructors = filterAvailableInstructors;
        //#endregion
    }

})();
